@import "styles/mixins";


.dosage,
.thc,
.cbd {
  color: var(--clr-neutral-900);
  font-weight: 500!important;
  font-size: 0.75rem!important;
  -webkit-font-smoothing: antialiased; 
  &::before{
    color: var(--clr-neutral-650);
    padding-right:5px;
  }
}

.thc::before {
  content: "THC";
}

.cbd::before {
  content: "CBD";
}

.thc::after,
.cbd::after {
  content: "%";
}

.dosage span {
  color:var(--clr-neutral-650);
}